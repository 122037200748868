<script lang="ts" setup>
import { toTypedSchema } from "@vee-validate/zod";
import { useForm } from "vee-validate";
import { z } from "zod";

const { t } = useI18n({
  useScope: "local",
});

const emit = defineEmits(["submit"]);

const validationRules = z.object({
  email: z.string({ required_error: t("error-email-required") }).email({ message: t("error-email-valid") }),
  password: z.string(),
  stayLoggedIn: z.boolean().default(false),
});

const validationSchema = toTypedSchema(validationRules);

const { defineField, handleSubmit, errors } = useForm({ validationSchema });

const [email, emailAttrs] = defineField("email");
const [password, passwordAttrs] = defineField("password");
const [stayLoggedIn, stayLoggedInAttrs] = defineField("stayLoggedIn");

const onSubmit = handleSubmit((values) => {
  emit("submit", values);
});
</script>

<template>
  <div>
    <form @submit.prevent="onSubmit" class="form">
      <div>
        <tw-form-input-email
          name="email"
          :errors="errors.email"
          v-model="email"
          v-bind="emailAttrs"
          :placeholder="t('placeholder-email')"
          autocomplete="email"
          required
        />
      </div>
      <div>
        <tw-form-input-password
          name="password"
          :errors="errors.password"
          v-model="password"
          v-bind="passwordAttrs"
          :placeholder="t('placeholder-password')"
          autocomplete="current-password"
          required
        />
      </div>
      <div>
        <tw-form-input-checkbox v-model="stayLoggedIn" v-bind="stayLoggedInAttrs">
          {{ t("label-stay-logged-in") }}
        </tw-form-input-checkbox>
      </div>
      <div class="button-wrapper">
        <tw-ui-action is="button" variant="button-primary" type="submit" class="button">
          {{ t("button-login") }}
        </tw-ui-action>
      </div>
    </form>
  </div>
</template>

<style scoped lang="scss">
.form {
  gap: 1rem;
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  flex-direction: column;

  & > * {
    width: 100%;
  }
}

.button-wrapper {
  display: flex;
  text-align: center;
  padding-top: 0.5rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.button {
  width: 100%;
  max-width: 400px;
}
</style>

<i18n lang="json">
{
  "de": {
    "placeholder-email": "E-Mail-Adresse",
    "placeholder-password": "Passwort",
    "label-stay-logged-in": "Angemeldet bleiben",
    "button-login": "Anmelden",
    "error-email-required": "E-Mail-Adresse ist erforderlich",
    "error-email-valid": "Bitte gib eine gültige E-Mail-Adresse ein"
  },
  "en": {
    "placeholder-email": "Email address",
    "placeholder-password": "Password",
    "label-stay-logged-in": "Stay logged in",
    "button-login": "Login",
    "error-email-required": "Email address is required",
    "error-email-valid": "Please provide a valid email address"
  }
}
</i18n>
